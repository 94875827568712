import parse from 'html-react-parser'

function Content({text, title = null, subtitle= null}){
    return (
        <>
            {title && (
                <div className="modal__title">
                    {parse(title)}
                </div>
            )}
            {subtitle && (
                <div className="modal__subtitle">
                    <span>{subtitle}</span>
                </div>
            )}
            <div className="modal__txt">
                {parse(text)}
            </div>
        </>
    );
}

export default Content;
