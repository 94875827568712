import CustomScroll from 'react-custom-scroll';
import {useModal} from "../context";
import {Link, useNavigate, Navigate} from 'react-router-dom'
import React, {useState, useRef, useEffect} from "react";
import parse from "html-react-parser";
import { useForm, SubmitHandler } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Content from "../components/modals/Content";
import Info from "../components/modals/Info";
import Sms from "../components/modals/Sms";
import Recovery from "../components/modals/Recovery";

function Login() {
    let navigate = useNavigate();
    const [content, setContent] = useState(null);
    const [reg, setReg] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitting2, setIsSubmitting2] = useState(false);

    const { show, hide} = useModal();

    const schema = yup.object({
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        password: yup.string().min(6, 'Мин. 6 символов').required('Некорректный пароль'),
    }).required();

    const schemaReg = yup.object({
        sname: yup.string().required('Обязательно к заполнению'),
        name: yup.string().required('Обязательно к заполнению'),
        email: yup.string().email('Некорректный E-mail').required('Обязательно к заполнению'),
        pass1: yup.string().min(6, 'Мин. 6 символов').required('Некорректный пароль'),
        pass2: yup.string().nullable().transform((o, c) => o === "" ? null : c).min(6, 'Мин. 6 символов').oneOf([yup.ref('pass1'), null], 'Пароли не совпадают'),
        phone:
            yup.string()
                .test("len", 'Некорректный телефон', (val) => {
                    const val_length_without_dashes = val.replace('+', "").replace(/-|_| /g, "").length;
                    return val_length_without_dashes === 11;
                }),
        agency: yup.string().required('Обязательно к заполнению'),
        inn: yup.string().nullable(),
        site: yup.string().nullable(),
        agree1: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree2: yup.boolean().oneOf([true], 'Необходимо согласие'),
    }).required();

    const {
        register,
        setError,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema)
    });

    const {
        register: register2,
        reset: reset2,
        setValue: setValue2,
        getValues: getValues2,
        setError: setError2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2
    } = useForm({
        resolver: yupResolver(schemaReg)
    });

    useEffect(()=> {
        if(window.location.hash) {
            if(window.location.hash === '#recovery'){
                show(<Recovery close={hide}/>, "modal--sms modal--recovery");
                window.history.pushState({param: 'Value'}, '', '/dev/login');
            }
        }
    }, []);

    const onSubmit2 = async (data) => {
        setIsSubmitting2(true);
        const formData = new FormData();
        const phone = data.phone.replace(/-|_| /g, "");
        formData.append("email", data.email);
        formData.append("phone", phone);

        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/preRegistration', {
            method: "POST",
            body: formData,
        }).then((response) => {
            if(response.ok){
                return response.json().then((data) => {
                    if(data){
                        show(<Sms phone={phone} sendReg={registerUser} close={hide}/>, "modal--sms");
                    }else{

                    }
                    setIsSubmitting2(false);
                });
            }else{
                return response.json().then((error) => {
                    console.log(error);
                    switch(error.code.toString()){
                        case '406':
                            setError2('email', { type: 'custom', message: error.message});
                            break;

                        case '407':
                            setError2('phone', { type: 'custom', message: error.message});
                            break;
                    }
                    setIsSubmitting2(false);
                });

            }
        }).catch((error) => {
            console.log(error);
            setIsSubmitting2(false);
        });
    }

    const registerUser = async() => {
        setIsSubmitting2(true);
        const data = getValues2();
        const formData = new FormData();
        formData.append("sname", data.sname);
        formData.append("name", data.name);
        formData.append("email", data.email.toLowerCase());
        formData.append("password", data.pass1);
        formData.append("phone", data.phone.replace(/-|_| /g, ""));
        formData.append("agency", data.agency);
        formData.append("inn", data.inn);
        formData.append("site", data.site);

        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/registration', {
            method: "POST",
            body: formData,
        }).then((response) => {
            if(response.ok){
                return response.json().then((data) => {
                    show(<Info text="Благодарим за регистрацию" close={hide}/>, "modal--info");
                    setIsSubmitting2(false);
                    reset2();
                    setValue2('phone', '');
                    setReg(false);
                });
            }
        }).catch((error) => {
            setIsSubmitting2(false);
        });
    }

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("username", data.email.toLowerCase());
        formData.append("password", data.password);

        await fetch(process.env.REACT_APP_API_PROXY + 'jwt-auth/v1/token', {
            method: "POST",
            body: formData,
        }).then((response) => response.json()).then((response) => {

            if(response.code){
                switch(response.code) {
                    case '[jwt_auth] incorrect_password':
                        setError('password', { type: 'custom', message: 'Введённый вами пароль неверен.'});
                        break;
                    case '[jwt_auth] invalid_email':
                        setError('email', { type: 'custom', message: 'Пользователь не найден.'});
                        break;
                }
            }else{
                if(response.token){
                    localStorage.setItem('auth_key', response.token);
                    navigate("/");
                }

                if(response.error){
                    setError('email', { type: 'custom', message: response.error});
                }
            }



            /*if(response.result){

            }else{
                if(response.error.email){
                    setError('email', { type: 'custom', message: response.error.email[0] });
                }

                if(response.error.login){
                    setError('email', { type: 'custom', message: response.error.login[0] });
                }

                if(response.error.password){
                    setError('password', { type: 'custom', message: response.error.password[0]});
                }
            }*/

            setIsSubmitting(false);
        });
    };

    useEffect(()=>{
        getContent();
    }, []);

    const getContent = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/getContent', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then(response => {
                setContent(response);
            });
    }

    const sendSms = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/sendSms', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then(response => {
                console.log(response);
            });
    }

    return (
        <div className="sc sc__full sc__login">
            <div className="logo">
                <svg width="115" height="20" viewBox="0 0 115 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44.2738 2.16133C45.6203 0.308783 48.3653 -0.251515 50.4733 0.0982664C51.9606 0.345488 53.1983 1.07798 54.1863 2.29573C54.1945 2.30621 54.2006 2.31831 54.2041 2.33128C54.2077 2.34425 54.2086 2.35783 54.2068 2.37118C54.205 2.38453 54.2006 2.39736 54.1938 2.40889C54.1871 2.42042 54.1781 2.43041 54.1674 2.43824L52.8556 3.41471C52.8331 3.43164 52.8048 3.43859 52.7771 3.43404C52.7493 3.42949 52.7242 3.4138 52.7074 3.39042C51.3925 1.5735 49.1268 1.1719 47.126 1.99453C45.4201 2.69409 44.6838 4.31831 45.111 6.17895C45.4327 7.58293 46.6294 8.33593 47.8749 8.75373C50.9321 9.77878 54.046 10.3018 54.4922 13.8984C54.8312 16.6367 53.379 18.797 50.88 19.6342C48.124 20.5588 44.6049 19.7265 42.8343 17.1841C42.8278 17.1747 42.8232 17.1641 42.8208 17.1528C42.8184 17.1415 42.8182 17.1299 42.8203 17.1186C42.8223 17.1072 42.8266 17.0964 42.8328 17.0868C42.839 17.0772 42.847 17.069 42.8564 17.0626L44.3196 16.0538C44.3317 16.0454 44.3453 16.0397 44.3597 16.0369C44.374 16.0341 44.3888 16.0343 44.4031 16.0375C44.4173 16.0406 44.4308 16.0467 44.4428 16.0554C44.4547 16.064 44.4648 16.075 44.4725 16.0878C45.1862 17.2483 46.1974 17.9695 47.506 18.2512C49.8505 18.7549 52.5198 17.712 52.7847 15.0125C53.1142 11.6799 49.6707 11.2767 47.4729 10.4233C45.923 9.82088 44.7894 9.3286 44.0027 8.02339C42.9321 6.24534 43.0346 3.86489 44.2738 2.16133Z" fill="#2F3338"/>
                    <path d="M7.85298 17.0124L13.797 0.527367C13.8018 0.514564 13.8102 0.503539 13.8212 0.495737C13.8321 0.487935 13.8451 0.48372 13.8585 0.483645L15.5408 0.465832C15.5516 0.465627 15.5623 0.468158 15.572 0.473203C15.5817 0.478247 15.59 0.485651 15.5962 0.494765C15.6024 0.503878 15.6064 0.514422 15.6077 0.525471C15.609 0.53652 15.6077 0.547737 15.6038 0.558135L8.61294 19.4479C8.60817 19.4607 8.59975 19.4718 8.58877 19.4796C8.5778 19.4874 8.56479 19.4916 8.55145 19.4916L6.92591 19.5014C6.91256 19.5013 6.89955 19.4971 6.88858 19.4893C6.87761 19.4815 6.86918 19.4704 6.86442 19.4576L0.00437151 0.561374C0.000498259 0.550975 -0.000830328 0.539759 0.000502393 0.52871C0.00183511 0.517661 0.00578856 0.507117 0.0120153 0.498003C0.0182421 0.48889 0.0265518 0.481486 0.0362152 0.476441C0.0458785 0.471397 0.0565997 0.468866 0.0674378 0.469071L1.77181 0.475548C1.78515 0.475623 1.79816 0.479838 1.80914 0.48764C1.82011 0.495442 1.82853 0.506467 1.8333 0.51927L7.72843 17.0124C7.73306 17.0256 7.74152 17.0369 7.75266 17.045C7.76381 17.053 7.77709 17.0573 7.79071 17.0573C7.80432 17.0573 7.81761 17.053 7.82875 17.045C7.8399 17.0369 7.84836 17.0256 7.85298 17.0124Z" fill="#2F3338"/>
                    <path d="M25.1742 17.8804L35.0078 17.8788C35.0321 17.8788 35.0553 17.8887 35.0725 17.9063C35.0896 17.9239 35.0993 17.9478 35.0993 17.9727V19.4042C35.0993 19.4165 35.0969 19.4288 35.0923 19.4402C35.0877 19.4516 35.081 19.4619 35.0725 19.4706C35.064 19.4794 35.0539 19.4863 35.0428 19.491C35.0317 19.4957 35.0198 19.4981 35.0078 19.4981H23.4446C23.4326 19.4981 23.4207 19.4957 23.4096 19.491C23.3985 19.4863 23.3884 19.4794 23.3799 19.4706C23.3714 19.4619 23.3647 19.4516 23.3601 19.4402C23.3555 19.4288 23.3531 19.4165 23.3531 19.4042V0.564626C23.3531 0.539716 23.3628 0.515827 23.3799 0.498213C23.3971 0.480599 23.4203 0.470703 23.4446 0.470703H34.5727C34.5969 0.470703 34.6202 0.480599 34.6373 0.498213C34.6545 0.515827 34.6641 0.539716 34.6641 0.564626L34.6673 1.99614C34.6673 2.02105 34.6576 2.04494 34.6405 2.06255C34.6233 2.08016 34.6001 2.09006 34.5758 2.09006H25.1805C25.1685 2.09006 25.1566 2.09249 25.1455 2.09721C25.1344 2.10193 25.1243 2.10885 25.1158 2.11757C25.1073 2.12629 25.1006 2.13664 25.096 2.14804C25.0914 2.15943 25.0891 2.17165 25.0891 2.18398L25.0827 8.75857C25.0827 8.78348 25.0924 8.80737 25.1095 8.82498C25.1267 8.84259 25.1499 8.85249 25.1742 8.85249L33.983 8.84925C33.995 8.84925 34.0069 8.85168 34.018 8.8564C34.0291 8.86112 34.0392 8.86804 34.0477 8.87676C34.0561 8.88548 34.0629 8.89584 34.0675 8.90723C34.0721 8.91863 34.0744 8.93084 34.0744 8.94317V10.3779C34.0744 10.3903 34.0721 10.4025 34.0675 10.4139C34.0629 10.4253 34.0561 10.4356 34.0477 10.4443C34.0392 10.4531 34.0291 10.46 34.018 10.4647C34.0069 10.4694 33.995 10.4718 33.983 10.4718L25.1821 10.467C25.1578 10.467 25.1346 10.4769 25.1174 10.4945C25.1003 10.5121 25.0906 10.536 25.0906 10.5609L25.0827 17.7865C25.0827 17.8114 25.0924 17.8353 25.1095 17.8529C25.1267 17.8705 25.1499 17.8804 25.1742 17.8804Z" fill="#2F3338"/>
                    <path d="M65.01 10.4799C64.9908 10.4799 64.9724 10.4878 64.9588 10.5018C64.9452 10.5157 64.9375 10.5347 64.9375 10.5544V19.3994C64.9375 19.4119 64.9352 19.4243 64.9306 19.4359C64.926 19.4475 64.9192 19.458 64.9107 19.4669C64.9022 19.4758 64.8922 19.4828 64.8811 19.4876C64.87 19.4924 64.8581 19.4949 64.8461 19.4949H63.3341C63.3098 19.4949 63.2866 19.485 63.2694 19.4674C63.2523 19.4498 63.2426 19.4259 63.2426 19.401V0.545186C63.2426 0.526718 63.2498 0.509008 63.2625 0.495949C63.2752 0.48289 63.2924 0.475554 63.3104 0.475554C65.0962 0.463678 66.8868 0.465838 68.6821 0.482031C71.5106 0.507941 74.3943 1.82934 74.5157 5.16683C74.6387 8.56586 72.3053 10.3714 69.1835 10.433C67.7886 10.46 66.3975 10.4756 65.01 10.4799ZM69.2402 8.83791C70.5205 8.81686 72.2895 8.05576 72.6285 6.62749C73.3537 3.57986 71.2647 2.09815 68.6427 2.09167C67.4392 2.08843 66.2409 2.08843 65.0479 2.09167C65.0185 2.0921 64.9904 2.1044 64.9698 2.12591C64.9491 2.14743 64.9375 2.17643 64.9375 2.20665V8.73103C64.9375 8.76039 64.9488 8.78857 64.9688 8.80947C64.9889 8.83038 65.0162 8.84234 65.0447 8.84277C66.4511 8.86436 67.8496 8.86274 69.2402 8.83791Z" fill="#2F3338"/>
                    <path d="M84.6553 17.8788H94.4842C94.5084 17.8788 94.5317 17.8887 94.5488 17.9063C94.566 17.9239 94.5756 17.9478 94.5756 17.9727L94.5803 19.4042C94.5803 19.4165 94.578 19.4288 94.5734 19.4402C94.5688 19.4516 94.562 19.4619 94.5536 19.4706C94.5451 19.4794 94.535 19.4863 94.5239 19.491C94.5128 19.4957 94.5009 19.4981 94.4889 19.4981H82.9241C82.9121 19.4981 82.9002 19.4957 82.8891 19.491C82.878 19.4863 82.8679 19.4794 82.8594 19.4706C82.8509 19.4619 82.8442 19.4516 82.8396 19.4402C82.835 19.4288 82.8326 19.4165 82.8326 19.4042V0.564626C82.8326 0.539716 82.8423 0.515827 82.8594 0.498213C82.8766 0.480599 82.8998 0.470703 82.9241 0.470703H94.0916C94.1158 0.470703 94.1391 0.480599 94.1562 0.498213C94.1734 0.515827 94.183 0.539716 94.183 0.564626L94.1862 1.99614C94.1862 2.02105 94.1765 2.04494 94.1594 2.06255C94.1422 2.08016 94.119 2.09006 94.0947 2.09006H84.6553C84.631 2.09006 84.6078 2.09995 84.5906 2.11757C84.5735 2.13518 84.5638 2.15907 84.5638 2.18398L84.5685 8.75857C84.5685 8.78348 84.5782 8.80737 84.5953 8.82498C84.6125 8.84259 84.6357 8.85249 84.66 8.85249L93.4609 8.84925C93.4852 8.84925 93.5084 8.85915 93.5256 8.87676C93.5427 8.89438 93.5524 8.91826 93.5524 8.94317L93.5555 10.3763C93.5555 10.3886 93.5531 10.4009 93.5485 10.4122C93.5439 10.4236 93.5372 10.434 93.5287 10.4427C93.5202 10.4514 93.5102 10.4584 93.4991 10.4631C93.488 10.4678 93.4761 10.4702 93.4641 10.4702H84.66C84.6357 10.4702 84.6125 10.4801 84.5953 10.4977C84.5782 10.5154 84.5685 10.5392 84.5685 10.5641L84.5638 17.7849C84.5638 17.8098 84.5735 17.8337 84.5906 17.8513C84.6078 17.8689 84.631 17.8788 84.6553 17.8788Z" fill="#2F3338"/>
                    <path d="M109.787 10.3504C109.771 10.3525 109.756 10.3588 109.742 10.3686C109.729 10.3784 109.718 10.3915 109.711 10.4066C109.704 10.4218 109.701 10.4385 109.702 10.4553C109.703 10.472 109.708 10.4883 109.716 10.5026L114.985 19.4237C115.014 19.4722 115.001 19.4965 114.946 19.4965L113.164 19.4998C113.14 19.4998 113.116 19.4933 113.095 19.4808C113.073 19.4683 113.056 19.4503 113.043 19.4285L107.917 10.5399C107.905 10.5187 107.887 10.5011 107.866 10.4889C107.845 10.4767 107.822 10.4703 107.797 10.4702H104.728C104.715 10.4702 104.702 10.4729 104.69 10.478C104.678 10.4832 104.667 10.4907 104.658 10.5002C104.649 10.5097 104.642 10.521 104.637 10.5333C104.632 10.5457 104.63 10.5589 104.63 10.5722L104.632 19.401C104.632 19.4267 104.622 19.4515 104.604 19.4697C104.586 19.4879 104.562 19.4981 104.537 19.4981H103.034C103.008 19.4981 102.983 19.4874 102.964 19.4683C102.946 19.4491 102.935 19.4232 102.935 19.3961V0.546816C102.935 0.529208 102.942 0.51232 102.954 0.499869C102.966 0.487418 102.983 0.480423 103 0.480423C104.985 0.453433 106.974 0.46207 108.966 0.506332C110.514 0.540339 112.486 1.1897 113.481 2.51757C114.732 4.18875 114.7 7.06796 113.314 8.65655C112.471 9.62331 111.06 10.1739 109.787 10.3504ZM112.01 7.44365C113.617 5.12959 112.086 2.3945 109.47 2.16617C108.224 2.05714 106.641 2.03932 104.723 2.11273C104.698 2.11357 104.674 2.12452 104.657 2.14326C104.64 2.162 104.63 2.18706 104.63 2.21313V8.76019C104.63 8.7831 104.639 8.80511 104.655 8.82146C104.671 8.83781 104.692 8.84721 104.715 8.84764C106.67 8.86221 110.687 9.35125 112.01 7.44365Z" fill="#2F3338"/>
                </svg>
            </div>
            <div className="decor decor--login-2"/>
            <div className="decor decor--login-1"/>
            <div className="decor decor--login-3"/>
            <div className="container">
                <div className="login__box">
                    <div className="login__box-hd">
                        <div className="login__box-title text-center">
                            Кабинет брокера
                        </div>
                        <div className="login__box-subtitle text-center">
                            вход
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={'login__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="form__it">
                                <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                                    <input
                                        type="text"
                                        id="email"
                                        autoComplete="username"
                                        placeholder="E-mail"
                                        {...register("email")}
                                    />
                                </div>
                                {errors.email && (
                                    <p className="error__message">
                                        {errors.email?.message}
                                    </p>
                                )}
                            </div>
                            <div className="form__it">
                                <div className={'form__field' + (errors.password ? ' has-error' : '')}>
                                    <input
                                        type="password"
                                        id="pass"
                                        autoComplete="current-password"
                                        placeholder="Пароль"
                                        {...register("password")}
                                    />
                                </div>
                                {errors.password && (
                                    <p className="error__message">
                                        {errors.password?.message}
                                    </p>
                                )}
                                <div className="recovery__link d-flex justify-content-center">
                                    <a onClick={()=>{
                                        show(<Recovery close={hide}/>, "modal--sms modal--recovery");
                                    }}>Забыли пароль?</a>
                                </div>
                            </div>
                        </div>
                        <div className="form__actions d-flex justify-content-center">
                            <button type="submit" className="btn btn--white d-flex align-items-center justify-content-center">
                                войти
                            </button>
                        </div>
                    </form>
                    <div className="login__bottom">
                        <div className="d-flex justify-content-center">
                            <button onClick={()=>{
                                setReg(true);
                            }} type="button" className="btn btn--white d-flex align-items-center justify-content-center">
                                регистрация
                            </button>
                        </div>
                        <div className="login__form-hint text-center">
                            Если у вас еще нет доступа в кабинет брокера Vesper, пройдите регистрацию
                        </div>
                    </div>
                </div>
            </div>
            <div className={'reg__box' + (reg ? ' active' : '')}>
                <div onClick={()=>{
                    setReg(false);
                }} className="reg__box-close">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.2806 18.2193C19.3503 18.289 19.4056 18.3717 19.4433 18.4628C19.481 18.5538 19.5004 18.6514 19.5004 18.7499C19.5004 18.8485 19.481 18.9461 19.4433 19.0371C19.4056 19.1281 19.3503 19.2109 19.2806 19.2806C19.2109 19.3502 19.1282 19.4055 19.0372 19.4432C18.9461 19.4809 18.8485 19.5003 18.75 19.5003C18.6514 19.5003 18.5539 19.4809 18.4628 19.4432C18.3718 19.4055 18.289 19.3502 18.2194 19.2806L12 13.0602L5.78061 19.2806C5.63988 19.4213 5.44901 19.5003 5.24999 19.5003C5.05097 19.5003 4.8601 19.4213 4.71936 19.2806C4.57863 19.1398 4.49957 18.949 4.49957 18.7499C4.49957 18.5509 4.57863 18.36 4.71936 18.2193L10.9397 11.9999L4.71936 5.78055C4.57863 5.63982 4.49957 5.44895 4.49957 5.24993C4.49957 5.05091 4.57863 4.86003 4.71936 4.7193C4.8601 4.57857 5.05097 4.49951 5.24999 4.49951C5.44901 4.49951 5.63988 4.57857 5.78061 4.7193L12 10.9396L18.2194 4.7193C18.3601 4.57857 18.551 4.49951 18.75 4.49951C18.949 4.49951 19.1399 4.57857 19.2806 4.7193C19.4213 4.86003 19.5004 5.05091 19.5004 5.24993C19.5004 5.44895 19.4213 5.63982 19.2806 5.78055L13.0603 11.9999L19.2806 18.2193Z" fill="#6A6A6A"/>
                    </svg>
                </div>
                <div className="reg__box-title text-center">
                    регистрация
                </div>
                <form onSubmit={handleSubmit2(onSubmit2)} className={'reg__form' + (isSubmitting2 ? ' form--loading' : '')}>
                    <div className="form__list">
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl">
                                фамилия*
                            </div>
                            <div className={'form__field' + (errors2.sname ? ' has-error' : '')}>
                                <input
                                    type="text"
                                    placeholder=""
                                    {...register2("sname")}
                                />
                            </div>
                            {errors2.sname && (
                                <p className="error__message">
                                    {errors2.sname?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl">
                                Имя*
                            </div>
                            <div className={'form__field' + (errors2.name ? ' has-error' : '')}>
                                <input
                                    type="text"
                                    placeholder=""
                                    {...register2("name")}
                                />
                            </div>
                            {errors2.name && (
                                <p className="error__message">
                                    {errors2.name?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl">
                                Email*
                            </div>
                            <div className={'form__field' + (errors2.email ? ' has-error' : '')}>
                                <input
                                    type="text"
                                    placeholder=""
                                    {...register2("email")}
                                />
                            </div>
                            {errors2.email && (
                                <p className="error__message">
                                    {errors2.email?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl">
                                Пароль*
                            </div>
                            <div className={'form__field' + (errors2.pass1 ? ' has-error' : '')}>
                                <input
                                    type="password"
                                    placeholder="Придумайте пароль"
                                    {...register2("pass1")}
                                />
                            </div>
                            {errors2.pass1 && (
                                <p className="error__message">
                                    {errors2.pass1?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl"></div>
                            <div className={'form__field' + (errors2.pass2 ? ' has-error' : '')}>
                                <input
                                    type="password"
                                    placeholder="Повторите пароль"
                                    {...register2("pass2")}
                                />
                            </div>
                            {errors2.pass2 && (
                                <p className="error__message">
                                    {errors2.pass2?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl">
                                Телефон*
                            </div>
                            <div className={'form__field' + (errors2.phone ? ' has-error' : '')}>
                                <InputMask
                                    autoComplete="nope"
                                    type="text"
                                    id="phone"
                                    placeholder="+7"
                                    mask="+7 999-999-99-99"
                                    alwaysShowMask={false}
                                    {...register2("phone")}
                                />
                            </div>
                            {errors2.phone && (
                                <p className="error__message">
                                    {errors2.phone?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl">
                                Агентство*
                            </div>
                            <div className={'form__field ' + (errors2.agency ? ' has-error' : '')}>
                                <input
                                    type="text"
                                    {...register2("agency")}
                                />
                            </div>
                            {errors2.agency && (
                                <p className="error__message">
                                    {errors2.agency?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl">
                                ИНН
                            </div>
                            <div className={'form__field' + (errors2.inn ? ' has-error' : '')}>
                                <input
                                    type="text"
                                    {...register2("inn")}
                                />
                            </div>
                            {errors2.inn && (
                                <p className="error__message">
                                    {errors2.inn?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it d-flex align-items-center">
                            <div className="form__it-lbl">
                                сайт
                            </div>
                            <div className={'form__field' + (errors2.site ? ' has-error' : '')}>
                                <input
                                    type="text"
                                    {...register2("site")}
                                />
                            </div>
                            {errors2.site && (
                                <p className="error__message">
                                    {errors2.site?.message}
                                </p>
                            )}
                        </div>
                        <div className="form__it form__it--indent">
                            <div className={'checkbox__wrap' + (errors2.agree1 ? ' has-error' : '')}>
                                <label>
                                    <input type="checkbox"
                                           {...register2("agree1")}
                                    />
                                    <span className="checkbox__decor"></span>
                                    <span className="checkbox__title">
                                        Я согласен на обработку <a onClick={()=>{
                                        if(content.policy){
                                            show(<Content text={content.policy} close={hide}/>, "modal--content");
                                        }
                                    }}>персональных данных</a>
                                    </span>
                                </label>
                                {errors2.agree1 && (
                                    <p className="error__message">
                                        {errors2.agree1?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="form__it">
                            <div className={'checkbox__wrap' + (errors2.agree2 ? ' has-error' : '')}>
                                <label>
                                    <input type="checkbox"
                                           {...register2("agree2")}
                                    />
                                    <span className="checkbox__decor"></span>
                                    <span className="checkbox__title">
                                        Я ознакомлен и согласен с <a onClick={()=>{
                                        if(content.brokers){
                                            show(<Content text={content.brokers} close={hide}/>, "modal--content");
                                        }
                                    }}>регламентом брокеров</a>
                                    </span>
                                </label>
                                {errors2.agree2 && (
                                    <p className="error__message">
                                        {errors2.agree2?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="form__it form__it--actions d-flex justify-content-center">
                            <button type="submit" disabled={isSubmitting2} className="btn btn--dark tbn--small d-flex align-items-center justify-content-center">
                                отправить заявку
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="footer d-flex align-items-center">
                <div className="footer__it">
                    МОСКВА, CАДОВНИЧЕСКАЯ НАБЕРЕЖНАЯ, 75, F2
                </div>
                <div className="footer__it">
                    <a href="tel:+74952877799">+7 (495) 287-77-99</a>
                </div>
            </div>
        </div>
    );
}

export default Login;
